import React from 'react';
import {
    Route,
    Navigate,
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainPage from './containers/MainPage';
import BkkDetailsContent from './containers/BkkDetailsContent';
import SzombathelyDetailsContent from './containers/SzombathelyDetailsContent';
import KtiDetailsContent from './containers/KtiDetailsContent';
import { routes } from './routes';
import DispatchDetailsContent from './containers/DispatchDetailsContent';
import RideCardDetailsContent from './containers/RideCardDetailsContent';
import ReplayDetailsContent from './containers/ReplayDetailsContent';
import DriveDetailsContent from './containers/DriveDetailsContent';
import DetailsPage from './components/DetailsPage';
import ImpulseDetailsContent from './containers/ImpulseDetailsContent';

const RedirectToRoot = () => <Navigate to={routes.main} />;

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route index element={<MainPage />} />
            <Route element={<DetailsPage />}>
                <Route path={routes.replayProduct} element={<ReplayDetailsContent />} />
                <Route path={routes.dispatchProduct} element={<DispatchDetailsContent />} />
                <Route path={routes.rideProduct} element={<RideCardDetailsContent />} />
                <Route path={routes.driveProduct} element={<DriveDetailsContent />} />
                <Route path={routes.bkkStory} element={<BkkDetailsContent />} />
                <Route path={routes.szombathelyStory} element={<SzombathelyDetailsContent />} />
                <Route path={routes.ktiStory} element={<KtiDetailsContent />} />
                <Route path={routes.impulseStory} element={<ImpulseDetailsContent />} />
            </Route>
            <Route path="*" element={<RedirectToRoot />} />
        </>,
    ),
);

const App = () => (
    <>
        <Helmet>
            <title>realCity - Innovative public transport management software solutions - realCity.io</title>
            <meta
                name="description"
                content="realCity provides cutting-edge public transport management software and passenger information solutions. Get in touch and transform your public transport system!"
            />
            <meta
                name="keywords"
                content="public transport management software, public transport SaaS, passenger information system, fleet management software, real-time transport solutions, journey planning software, smart mobility solutions, transit data analytics"
            />
        </Helmet>
        <RouterProvider router={router} />
    </>
);
export default App;
