import React from 'react';
import { Helmet } from 'react-helmet';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import replayImage1 from '../assets/images/replay/replay_1.png';
import replayImage2 from '../assets/images/replay/replay_2.png';
import DetailsText from '../components/DetailsText';
import DetailsList from '../components/DetailsList';

const ReplayDetailsContent = () => (
    <>
        <Helmet>
            <title>realCity | replay – Traffic Analysis & Visualization system</title>
            <meta
                name="description"
                content="realCity | replay analyzes historical traffic data, compare planned vs. actual trips, detect inefficiencies, and generate detailed reports with interactive visualization tools."
            />
            <meta
                name="keywords"
                content="historical traffic data analysis, public transport performance review, route efficiency analysis, data-driven traffic optimization, interactive transport visualization"
            />
        </Helmet>
        <DetailsHeaderText
            category="Products"
            title="realCity | replay"
            subtitle="Traffic analysis and visualisation on historical data"
        />
        <DetailsText>
            <DetailsList>
                <li>Compare planned and realized trips</li>
                <li>Find the reasons behind problems</li>
                <li>Easily extend the tool with your own data sources</li>
                <li>Simple to deploy</li>
                <li>Numerous visualization tools and statistical graphs</li>
                <li>Detailed reports</li>
            </DetailsList>
        </DetailsText>
        <SmartCarousel
            images={[
                replayImage1,
                replayImage2,
            ]}
        />
    </>
);

export default ReplayDetailsContent;
