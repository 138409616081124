import type { PropsWithChildren } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../css/section-details-link.css';
import SectionDetailsLinkGeneric from './SectionDetailsLinkGeneric';

interface Props {
    navigateTo: string
}

const SectionDetailsLink = ({ navigateTo, ...rest }: PropsWithChildren<Props>) => (
    <SectionDetailsLinkGeneric component={Link} to={navigateTo} className={styles.root} {...rest} />
);

export default SectionDetailsLink;
