import React from 'react';
import { Helmet } from 'react-helmet';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import rideImage1 from '../assets/images/bkk/bkk_ride_1.png';
import rideImageTransparent from '../assets/images/ride/ride_transparent1.png';
import replayImage1 from '../assets/images/replay/replay_1.png';
import replayImage2 from '../assets/images/replay/replay_2.png';
import appStoreImage from '../assets/images/app-store-badge.png';
import googlePlayImage from '../assets/images/google-play-badge.png';
import webSiteImage from '../assets/images/bkk/bkk-ride-badge.png';
import DetailsText from '../components/DetailsText';
import DownloadLink from '../components/DownloadLink';
import DownloadLinks from '../components/DownloadLinks';
import DetailsList from '../components/DetailsList';

const BkkDetailsContent = () => (
    <>
        <Helmet>
            <title>BKK’s Public Transport Management - FUTÁR - realCity.io</title>
            <meta
                name="description"
                content="From traffic light priority to real-time journey planning, we serve 1M+ monthly users and optimize Budapest’s public transport with smart mobility solutions."
            />
            <meta
                name="keywords"
                content="public transport management system, real-time vehicle tracking, public transport optimization, multimodal journey planning, smart city mobility solutions, traffic light priority system, public transport analytics, passenger information apps"
            />
        </Helmet>
        <DetailsHeaderText
            category="Stories"
            title="Centre for Budapest Transport (BKK)"
        />
        <DetailsText>
            <p>
                Our team has been involved in the design and support of the Centre for Budapest
                Transport’s Traffic Management and Passenger Information System (FUTÁR) right from its
                start in 2012. The first module we developed was the Traffic Light Priority system which
                provides priority for public transport vehicles at traffic lights now in over 90
                intersections in Budapest.
            </p>
            <p>
                The next system to be implemented was our journey planner application for the web, going
                live in September 2014. A more advanced solution than Google Maps at the time with
                real-time vehicle locations and predictions, it was immediately embraced by the
                passengers and grabbed the attention of transportation professionals as well. In the
                following months, native Android, iOS and WindowsPhone applications rolled out, creating
                a foundation, on which our current out-of-the-box realCity | ride passenger information
                apps are built on.
            </p>
            <div>
                <p>
                    Over the last decade, we have not only redesigned these apps several times, but also
                    extended the trip planning functionality based on BKK’s custom orders, most of which we
                    also make available to our other clients as well. Some of these include:
                </p>
                <DetailsList singleColumn>
                    <li>Multimodal journey planning, with bike sharing integration (2015) </li>
                    <li>Facebook Messenger integration (2017)</li>
                    <li>Managing subscriptions, push notifications, alerts (2021)</li>
                    <li>Identity Management, social login possibilities (2022)</li>
                    <li>Recognition of the vehicle the passenger is traveling on, trip planning from the vehicle (2023)</li>
                    <li>Demand-responsive transport integration (2023)</li>
                </DetailsList>
                <p>
                    As of 2023, we serve 1.000.000 unique monthly users, make 55 million trip plans
                    annually and handle the real-time data of over 2500 vehicles in motion each day.
                </p>
                <p>
                    In 2016, we created realCity | replay, our fact data management system, which is an
                    application that facilitates the analysis of public transport events. During the
                    development, our company was closely cooperating with various departments of BKK in
                    order to create the best and the most useful features. Today, over 250 BKK employees
                    use this tool daily to investigate passenger complaints, gather traffic and
                    passenger counting data to improve the timetable and to increase the quality of
                    service
                </p>
            </div>
        </DetailsText>
        <SmartCarousel
            images={[
                rideImage1,
                rideImageTransparent,
                replayImage1,
                replayImage2,
            ]}
        />
        <DownloadLinks>
            <DownloadLink href="https://itunes.apple.com/app/apple-store/id916193835" imageSrc={appStoreImage} />
            <DownloadLink href="https://play.google.com/store/apps/details?id=hu.webvalto.bkkfutar" imageSrc={googlePlayImage} />
            <DownloadLink href="http://futar.bkk.hu" imageSrc={webSiteImage} />
        </DownloadLinks>
    </>
);

export default BkkDetailsContent;
