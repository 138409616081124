import React from 'react';
import { Helmet } from 'react-helmet';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import driveImage1 from '../assets/images/drive/drive_1.jpg';
import driveImage2 from '../assets/images/drive/drive_2.png';
import DetailsText from '../components/DetailsText';
import DetailsList from '../components/DetailsList';

const DriveDetailsContent = () => (
    <>
        <Helmet>
            <title>realCity drive - tablet based on-board unit</title>
            <meta
                name="description"
                content="Enhance your fleet with a portable, tablet-based on-board unit. Easy installation, real-time dispatch integration, and a user-friendly interface for seamless navigation."
            />
            <meta
                name="keywords"
                content="real-time vehicle tracking, fleet, management system, public transport technology, IP-based vehicle integration, driver assistance system"
            />
        </Helmet>
        <DetailsHeaderText
            category="Products"
            title="realCity | drive"
            subtitle="Portable tablet-based on-board unit"
        />
        <DetailsText>
            <DetailsList>
                <li>Easy installation of the system to vehicles</li>
                <li>Straightforward, map-based UI helps drivers in navigation</li>
                <li>Fully integrated with the dispatch system, by providing real-time information</li>
                <li>Multi-level integration option: simple-portable or deep vehicle integration</li>
                <li>IP-based connection to any other vehicle system</li>
                <li>Passenger demanded journeys can be served</li>
                <li>
                    The device is detachable from the vehicle in seconds and it is capable of running other applications
                    if it is allowed by the operator company
                </li>
            </DetailsList>
        </DetailsText>
        <SmartCarousel
            images={[
                driveImage1,
                driveImage2,
            ]}
        />
    </>
);

export default DriveDetailsContent;
