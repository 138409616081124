import React from 'react';
import type { DetailsLinkProps } from './DetailsLink';
import DetailsLink from './DetailsLink';
import * as styles from '../css/section-details-link.css';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SectionDetailsLinkGeneric = <T,> ({ ...rest }: DetailsLinkProps<T>) => (
    <DetailsLink className={styles.root} {...rest} />
);

export default SectionDetailsLinkGeneric;
