import type { ComponentType, ElementType, PropsWithChildren } from 'react';
import React from 'react';
import * as styles from '../css/details-link.css';
import { classNames } from '../utils';

export type DetailsLinkProps<T> = T & {
    component: ElementType<T>;
    className?: string;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DetailsLink = <T,>({ component, className, ...rest }: PropsWithChildren<DetailsLinkProps<T>>) => {
    const Component = component as ComponentType<T>;
    return (
        <Component className={classNames(styles.root, className)} {...rest as T} />
    );
};

export default DetailsLink;
